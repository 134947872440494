import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/image/logo_myculture.svg'
import _imports_1 from '@/assets/icons/white_arrow_right_24px.png'


const _hoisted_1 = { class: "menu-container flex-center" }
const _hoisted_2 = { class: "menu-navbar-for-core-value" }
const _hoisted_3 = { class: "max-m-none" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  src: _imports_0,
  alt: "Happily logo",
  class: "company-logo-image",
  style: {"width":"18rem"}
}
const _hoisted_6 = { class: "flex-center ml-auto" }
const _hoisted_7 = { class: "" }
const _hoisted_8 = { class: "" }
const _hoisted_9 = {
  class: "body-width-reponsive-mobile",
  style: {"max-width":"1200px"}
}
const _hoisted_10 = { class: "pt-52" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_core_values_analyzer = _resolveComponent("main-core-values-analyzer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.companyLogoUrl)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.companyLogoUrl,
                alt: "Happily logo",
                class: "company-logo-image",
                style: {"width":"18rem"}
              }, null, 8, _hoisted_4))
            : (_openBlock(), _createElementBlock("img", _hoisted_5))
        ]),
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "" }, [
          _createElementVNode("div", { class: "" }, " ")
        ], -1)),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.gotoLoginPage && _ctx.gotoLoginPage(...args))),
            class: "ml-auto btn btn-44 btn-solid-primary",
            style: _normalizeStyle(`width: ${_ctx.$t('lang_code') == 'th' ? '6.8' : '5'}rem`)
          }, [
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("createAccount.Log_in")), 1)
          ], 4),
          _createElementVNode("div", {
            class: "ml-auto btn btn-44 btn-primary ml-12",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.gotoSignupPage && _ctx.gotoSignupPage(...args))),
            style: _normalizeStyle(`width: ${_ctx.$t('lang_code') == 'th' ? '16' : '9.6'}rem`)
          }, [
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("createAccount.Sign_up")), 1),
            _cache[2] || (_cache[2] = _createElementVNode("img", {
              src: _imports_1,
              class: "icon-24 max-m-none ml-16",
              alt: "icons_white_arrow_right_24px"
            }, null, -1))
          ], 4)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_main_core_values_analyzer)
      ])
    ])
  ], 64))
}